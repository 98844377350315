import React from "react";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  FlexProps,
  MenuItemProps,
} from "@chakra-ui/react";
import { useGetUserPermissions } from "network/User/get";
import { useRouter } from "next/router";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { IoMdPaperPlane } from "react-icons/io";
import { TbPlus } from "react-icons/tb";
import { TfiArrowsHorizontal } from "react-icons/tfi";
import theme from "ui/Themes/default";

export const MoveMoneyButton: React.FC<any> = () => {
  const router = useRouter();

  const { data: permissions } = useGetUserPermissions();
  const hasMoneyMovementPermissions =
    permissions && permissions.money_movement > 0;

  if (!hasMoneyMovementPermissions) return null;

  const menuListStyle: Partial<FlexProps> = {
    overflow: "hidden",
    right: 0,
    boxShadow: "0 10px 24px rgb(65 65 90 / 13%)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#1f1f30",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    position: "absolute",
    zIndex: 4,
  };

  const menuItemStyle: Partial<MenuItemProps> = {
    _hover: { bg: theme.colors.emerald_mint[30] },
    // _focus: { bg: "transparent" },
    px: "28px",
    py: "16px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "15px",
    whiteSpace: "nowrap",
  };

  return (
    <Menu placement="bottom-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              isOpen ? (
                <ChevronUpIcon boxSize="1.5em" />
              ) : (
                <ChevronDownIcon boxSize="1.5em" />
              )
            }
            isActive={isOpen}
            sx={{
              alignItems: "center",
              textDecoration: "none",
              whiteSpace: "nowrap",
              fontWeight: "semibold",
              borderRadius: "20px",
              bg: `linear-gradient(180deg, var(--chakra-colors-midnight_teal-200) -52.5%, var(--chakra-colors-midnight_teal-400) 100%)`,
              width: "190px",
              height: "40px",
              color: "white",
              paddingX: "32px",
              _active: {
                bg: "emerald_mint.500",
              },
              // backgroundSize: "100%", // Ensures smooth rendering
              // transition: "background 0.3s ease", // Smooth background transition
              _hover: {
                bg: isOpen
                  ? `var(--chakra-colors-emerald_mint-500)`
                  : `linear-gradient(180deg, var(--chakra-colors-midnight_teal-300) -52.5%, var(--chakra-colors-midnight_teal-500) 100%)`, // Subtle gradient change on hover
              },
            }}
          >
            Move Money
          </MenuButton>
          <MenuList {...menuListStyle} zIndex="popover">
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/money-movement/pay")}
            >
              <Icon as={IoMdPaperPlane} color="emerald_mint.500" mr={5} />
              <span>Pay Someone</span>
            </MenuItem>
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/accounts/linked")}
            >
              <Icon
                as={HiOutlineCurrencyDollar}
                color="emerald_mint.500"
                mr={5}
              />
              <span>Deposit or Withdraw Funds</span>
            </MenuItem>
            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/money-movement/transfer")}
            >
              <Icon as={TfiArrowsHorizontal} color="emerald_mint.500" mr={5} />
              <span>Transfer Between Accounts</span>
            </MenuItem>

            <MenuItem
              {...menuItemStyle}
              onClick={() => router.push("/money-movement/deposit")}
            >
              <Icon as={TbPlus} color="emerald_mint.500" mr={5} />
              <span>
                Deposit a Check<sup> Beta </sup>
              </span>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
